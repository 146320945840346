import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import HeroMobile from "./../../images/Nevroherb-mobile.jpg"
import HeroTablet from "./../../images/Nevroherb-tablet.jpg"
import HeroDesktop from "./../../images/Nevroherb-desktop.jpg"
import HeroXL from "./../../images/Nevroherb-desktop-xl.jpg"
import Feature1 from "./../../images/nevroherb-feature.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"

const NevroherbPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "NevroherbEn" }}>
    <Seo title="Nevroherb" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>NEVROHERB</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Nevroherb"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Nevroherb
          </h5>
          <h1 className="display-4 font-light">For the nervous system</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Nevroherb</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            For the nervous system
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Nevroherb is a natural herbal product with a calming and supportive
            effect on the nervous system.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/nevroherb-nervna-sistema-nervous-system"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Peace of mind is not difficult</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Спокойствието не е трудно"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Nevroherb is a natural product that supports the functions of the
            central nervous system. It has a calming effect on conditions such
            as neurosis, palpitations, melancholy and menopause. The specially
            selected combination of herbs helps to restore the body under stress
            and has a positive effect on the functional state of the nerves. As
            a result, the quality of sleep is improved.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality.
          </p>
          <p>
            Taking an extract with a proven effect ensures optimal impact on
            body functions. The plant extracts included in Nevroherb complement
            each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Lemon balm</strong> (Folia Melissae officinalis)
                    99,0 mg
                  </p>
                  <p>
                    <strong>Thyme stalk</strong> (Herba Serpilli) 66,0 mg
                  </p>
                  <p>
                    <strong>Cone hops</strong> (Strobuli lupuli) 66,0 mg
                  </p>
                  <p>
                    <strong>Yellow communica stalks</strong> (Herba meliloti)
                    33,0 mg
                  </p>
                  <p>
                    <strong>Mint leaves</strong> (Folia Menthae piperitae) 33,0
                    mg
                  </p>
                  <p>
                    <strong>Valerian roots</strong> (Rhisoma Valerianae) 33,0 mg
                  </p>

                  <p>Excipients: maltodextrin, magnesium stearate</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 times daily, 1-2 tablets after meals</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Do not exceed the recommended daily dose.
                    Do not use the product as a substitute for a varied diet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Nevroherb is a natural product that supports the functions
                    of the nervous system. In addition to diet, it helps the
                    body recover from stress. Has a positive effect on the
                    functional state of the nerves.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NevroherbPageEn